import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';

const Rsvp = (props) => {
  const [attending, setAttending] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [numberAttending, setNumberAttending] = useState('1');

  const onSubmit = () => {
    setSubmitted(true);
  };

  useEffect(() => {
    if (!attending) {
      setNumberAttending('0');
    }
    if (attending) {
      setNumberAttending('1');
    }
  }, [attending]);

  // const submitButtonDisabled = !(name && numberAttending) || submitted;

  // wedding is over, disable the button
  const submitButtonDisabled = true;

  return (
    <Layout fullMenu location={props.location} title="RSVP">
      <article id="main">
        <header>
          <h2>RSVP</h2>
          <p>Phúc Đáp</p>
        </header>
        <section className="wrapper style5 rsvp">
          <iframe
            title="hidden_iframe"
            name="hidden_iframe"
            id="hidden_iframe"
            style={{ display: 'none' }}
            onLoad={() => {
              if (submitted) {
                navigate('/thankyou');
              }
            }}
          ></iframe>

          <div className="inner">
            <p>
              Kindly reply by <b>December 4th, 2021</b>.
            </p>
            <p>
              Để giúp chúng tôi thuận tiện cho việc sắp xếp xin vui lòng phúc
              đáp trước ngày <b>4.12.2021</b>.
            </p>
            <hr />
            <form
              action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSd7ujH1CTks6JCLB5UFRR5UwHjhbDYht7LQ3tTsb0AmSFRkiA/formResponse"
              onSubmit={onSubmit}
              method="post"
              target="hidden_iframe"
            >
              <div className="row gtr-uniform form-row">
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="name">
                    Name / Quý danh
                  </label>
                  <input
                    type="text"
                    name="entry.1530049833"
                    id="name"
                    defaultValue=""
                    placeholder="Name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-4 col-12-small">
                  <div onChange={(e) => setAttending(e.target.value === 'Yes')}>
                    <input
                      type="radio"
                      id="attending-accept"
                      name="entry.449399535"
                      value="Yes"
                      defaultChecked
                    />
                    <label htmlFor="attending-accept">Attending</label>

                    <div className="col-4 col-12-small">
                      <input
                        type="radio"
                        id="attending-decline"
                        name="entry.449399535"
                        value="No"
                      />
                      <label htmlFor="attending-decline">
                        Regretfully Decline
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="row gtr-uniform form-row"
                style={{ display: attending ? 'block' : 'none' }}
              >
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="number-of-attendees">
                    Number of attendees / Số người tham dự
                  </label>
                  <input
                    type="text"
                    name="entry.1563001624"
                    id="number-of-attendees"
                    // defaultValue=""
                    placeholder="Number"
                    value={numberAttending}
                    onChange={(e) => setNumberAttending(e.target.value)}
                  />
                </div>
              </div>

              <div
                className="row gtr-uniform form-row"
                style={{ display: attending ? 'block' : 'none' }}
              >
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="names-of-guests">
                    Names of guests / Tên người tham dự
                  </label>
                  <input
                    type="text"
                    name="entry.1269211763"
                    id="names-of-guests"
                    defaultValue=""
                    placeholder="Names"
                  />
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-12">
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Send"
                        className={`primary ${
                          submitButtonDisabled ? 'disabled' : ''
                        }`}
                      />
                    </li>
                    {/* <li>
                      <input type="reset" value="Reset" />
                    </li> */}
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Rsvp;
